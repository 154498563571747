import { BookingPolicyDto } from '../types/shared-types';
import moment from 'moment';
import {
  Service,
  ServiceType as ServiceV2Type,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { Schedule } from '@wix/ambassador-schedule-server/types';

export const mapServiceToBookingPolicy = (
  service: Service,
  schedule?: Schedule,
  locale = 'en',
): BookingPolicyDto => {
  const serviceType = service.type;
  const firstSessionStart = schedule?.firstSessionStart?.toISOString();
  const lastSessionEnd = schedule?.lastSessionEnd?.toISOString();
  const policy = service.bookingPolicy;
  const serviceSchedule = service.schedule;
  if (service.type !== ServiceV2Type.COURSE) {
    return {
      isBookable: !!service.onlineBooking?.enabled,
      isFullyBooked: false,
      isTooEarlyToBook: false,
      timeUntilServiceIsOpen: '',
      isTooLateToBook: false,
      isServiceAvailable:
        serviceType === ServiceV2Type.CLASS
          ? isServiceHasSessions(
              serviceSchedule?.firstSessionStart?.toISOString(),
            ) && !isDatePassed(serviceSchedule?.lastSessionEnd?.toISOString())
          : true,
      isServiceStartedAndBookable: false,
      cancellationPolicy:
        service.bookingPolicy?.customPolicyDescription?.description,
    };
  }

  const shouldLimitTooEarly = !!policy?.limitEarlyBookingPolicy?.enabled;
  const bookBeforeStartMinutes =
    policy?.limitEarlyBookingPolicy?.earliestBookingInMinutes;

  const shouldLimitTooLate = !!policy?.limitLateBookingPolicy?.enabled;
  const bookUpToXMinutesBeforeStart =
    policy?.limitLateBookingPolicy?.latestBookingInMinutes;
  const bookAfterStart = policy?.bookAfterStartPolicy?.enabled;

  return {
    isBookable: !!service.onlineBooking?.enabled,
    isFullyBooked: !!schedule && isFullyBooked(schedule),
    numberOfSpotsLeft: getNumberOfSpotsLeft(schedule!),
    isTooEarlyToBook:
      shouldLimitTooEarly &&
      isTooEarlyToBook(firstSessionStart, bookBeforeStartMinutes),
    timeUntilServiceIsOpen: timeUntilServiceIsOpen(
      locale,
      firstSessionStart,
      bookBeforeStartMinutes,
    ),
    isTooLateToBook:
      shouldLimitTooLate &&
      isBookWindowEnded(
        firstSessionStart,
        bookUpToXMinutesBeforeStart,
        bookAfterStart,
      ),
    isServiceAvailable: schedule
      ? isServiceAvailable(firstSessionStart, lastSessionEnd)
      : true,
    isServiceStartedAndBookable: isServiceStartedAndBookable(
      firstSessionStart,
      lastSessionEnd,
      bookAfterStart,
    ),
    cancellationPolicy:
      service.bookingPolicy?.customPolicyDescription?.description,
  };
};
const isServiceStartedAndBookable = (
  firstSessionStart?: string,
  lastSessionEnd?: string,
  bookAfterStart?: boolean,
) => {
  return (
    isDatePassed(firstSessionStart) &&
    !isDatePassed(lastSessionEnd) &&
    canBeBookedAfterStart(bookAfterStart)
  );
};
const isTooEarlyToBook = (
  firstSessionStart?: string,
  bookBeforeStartMinutes?: number,
) => {
  return (
    isServiceHasSessions(firstSessionStart) &&
    !!bookBeforeStartMinutes &&
    minuteDifferencesBetweenSessionToPresentDate(firstSessionStart) >=
      bookBeforeStartMinutes
  );
};

const timeUntilServiceIsOpen = (
  locale: string,
  firstSessionStart?: string,
  bookBeforeStartMinutes?: number,
) => {
  return moment()
    .locale(locale)
    .to(moment(firstSessionStart).subtract(bookBeforeStartMinutes, 'minutes'));
};

const isServiceAvailable = (
  firstSessionStart?: string,
  lastSessionEnd?: string,
) => {
  return (
    isServiceHasSessions(firstSessionStart) && !isDatePassed(lastSessionEnd)
  );
};

const isDatePassed = (formattedDate?: string) => {
  const minuteDifferencesPresentToFormattedDate =
    minuteDifferencesBetweenSessionToPresentDate(formattedDate);
  return !!formattedDate && minuteDifferencesPresentToFormattedDate <= 0;
};

const isBookWindowEnded = (
  firstSessionStart?: string,
  bookUpToXMinutesBeforeStart?: number,
  bookAfterStart?: boolean,
) => {
  const minuteDifferencesPresentToFirstSession =
    minuteDifferencesBetweenSessionToPresentDate(firstSessionStart);
  return (
    isServiceHasSessions(firstSessionStart) &&
    !canBeBookedAfterStart(bookAfterStart) &&
    minuteDifferencesPresentToFirstSession <= bookUpToXMinutesBeforeStart!
  );
};

const canBeBookedAfterStart = (bookAfterStart) => bookAfterStart === true;

const isServiceHasSessions = (firstSessionStart?: string) => {
  return !!firstSessionStart;
};

const minuteDifferencesBetweenSessionToPresentDate = (sessionDate?: string) => {
  const now = moment();
  const sessionDateFormattedToMomentTime = moment(sessionDate);
  return sessionDate
    ? +sessionDateFormattedToMomentTime.diff(now, 'minutes')
    : 0;
};

const isFullyBooked = (schedule: Schedule) => {
  return getNumberOfSpotsLeft(schedule) <= 0;
};

const getNumberOfSpotsLeft = (schedule: Schedule) => {
  return (schedule?.capacity || 0) - (schedule?.totalNumberOfParticipants || 0);
};
