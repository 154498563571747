import { FailReasons } from '../constants';
import { WidgetConfig } from '../../../types/shared-types';
import { biDefaults } from '../bi/consts';
import {
  isServiceConnectedToPricingPlan,
  isServiceOfferedAsPricingPlan,
} from '@wix/bookings-uou-mappers';
import { ServiceType } from '@wix/bookings-uou-types';
import { getTrackingInfoForBookButtonClick } from '@wix/bookings-analytics-adapter';
import { isEcomSite } from '../../../api/siteIsEcom';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { navigateToBookingsFormPage } from '@wix/bookings-booking-navigation';
import { navigateToBookingsCalendarPage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import {
  isDemoMode,
  isPricingPlanInstalled as isPricingPlanInstalledUtils,
  isTemplateMode,
  trackAnalytics,
} from '@wix/bookings-catalog-calendar-viewer-utils';

export const handleNavigation = async ({
  config,
  isPreview,
  onNavigationFailed,
  locationId,
  timezone,
  flowAPI,
}: {
  config: WidgetConfig;
  isPreview: boolean;
  onNavigationFailed: ({ failReasons }: { failReasons: FailReasons[] }) => void;
  locationId?: string;
  timezone?: string;
  flowAPI: ControllerFlowAPI;
}) => {
  const featureEnabled = config.isFeatureEnabled;
  const siteIsEcom = await isEcomSite(flowAPI);
  const isPricingPlanInstalled = await isPricingPlanInstalledUtils(
    flowAPI.controllerConfig.wixCodeApi,
  );

  if (
    isPreview ||
    isDemoMode({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    }) ||
    isTemplateMode({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    }) ||
    (featureEnabled &&
      siteIsEcom &&
      (await isPricingPlanNavigateEnabled(
        isPricingPlanInstalled,
        config.serviceDto,
      )))
  ) {
    const referral = biDefaults.service_page_referral_info;
    const serviceSlug = config.serviceDto!.urlName;
    const isCourse = config.serviceDto!.type === ServiceType.COURSE;

    if (config.serviceDto) {
      const trackingInfo = getTrackingInfoForBookButtonClick({
        service: config.serviceDto,
        businessName: config.businessInfo.name || '',
      });

      trackAnalytics({
        wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
        eventId: trackingInfo.eventId as any,
        payload: trackingInfo.payload,
      });
    }

    const serviceId = config.serviceDto!.id;
    if (isCourse) {
      navigateToBookingsFormPage({
        platformAPIs: flowAPI.controllerConfig.platformAPIs,
        wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
        serviceId,
        serviceSlug,
        queryParams: { referral, timezone },
        isRemoveAnywhereEmbedEnabled: flowAPI.experiments.enabled(
          'specs.bookings.removeAnywhereEmbed',
        ),
      });
    } else {
      navigateToBookingsCalendarPage(
        flowAPI.controllerConfig.wixCodeApi,
        flowAPI.controllerConfig.platformAPIs,
        {
          serviceSlugOrBasket: serviceSlug,
          navigationContext: {
            referral,
            location: locationId,
            timezone,
          },
        },
      );
    }
  } else {
    if (!featureEnabled) {
      onNavigationFailed({ failReasons: [FailReasons.Premium] });
    } else if (!siteIsEcom) {
      onNavigationFailed({ failReasons: [FailReasons.NonEcomSite] });
    } else {
      const failReasons: FailReasons[] = [];
      if (!isPricingPlanInstalled) {
        failReasons.push(FailReasons.PricingPlanNotInstalled);
      }
      if (!isServiceConnectedToPricingPlan(config.serviceDto)) {
        failReasons.push(FailReasons.NoPlansAssignedToService);
      }
      onNavigationFailed({ failReasons });
    }
  }
};

const isPricingPlanNavigateEnabled = async (
  isPricingPlanInstalled: boolean,
  serviceDto,
) => {
  return (
    !isServiceOfferedAsPricingPlan(serviceDto, isPricingPlanInstalled) ||
    (isServiceConnectedToPricingPlan(serviceDto) && isPricingPlanInstalled)
  );
};
